import router from "../../../../router";

export default {
    name: "WarrantCalculator",
    created () {
        // this.$$.navbarAnimation()
        this.$nextTick(function () {
            this.ariseCombobox('#comboboxWarrants')
            // 获取ric
            this.getRiclist()
        });
    },
    mounted () {
        this.$nextTick(function () {

        })
    },
    data () {
        return {
            ricData: [],
            CalculatorData: [],
            exchangeratio: [],
            UnderlingTargetPrice: '',
            ResetUnderlingTargetPrice: '',
            UnderlingTargetPricecount: '',
            UnderlingTargetPricePercent: '',
            exercisePrice: '',
            CurrentWarrantBidV: '',
            CalBid: '',
            CurrencyAgain: '',
            Currency: '',
            newCalBid: '',
            CurrentWarrantBidVChange: '',
            date: '',
            convRatio: '',
            negitive: true,
            newnegitive: true,
            ricNmae: ''
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        /*获取ric list*/
        getRiclist: function () {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICList",
                data: { token: "webkey" },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        this.ricData = data.ric
                        this.ricNmae = this.ricData[0].ric
                        this.GetMarketData(this.ricData[0].ric)
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 接收Marke数据
        GetMarketData: function (ric) {
            $.ajax({
                type: "POST",
                url: this.$$.mibb + "GetMarketData",
                dataType: "json",
                data: { token: "webkey", type: "warrantdata", ric: ric },
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        this.CalculatorData = data[ric]
                        this.exchangeratio = data.exchangeratio
                        this.UnderlingTargetPrice = this.$$.formatNumber(data[ric].underlying_bid, 2, 1)
                        this.ResetUnderlingTargetPrice = this.$$.reservedNumber(data[ric].underlying_bid)
                        this.UnderlingTargetPricecount = this.$$.reservedNumber(data[ric].underlying_bid)
                        this.UnderlingTargetPricePercent = 0.0
                        this.exercisePrice = data[ric].exercise_price_cal
                        $(".ui-widget").find("input").val(data[ric].dsply_nmll);
                        this.CalculatorData.issuer_name = this.CalculatorData.issuer_name2.replace("MACQ", "Macquarie").toUpperCase()
                        this.Currency = this.$$.curreryRetrun(data[ric].currency)
                        this.CurrencyAgain = this.$$.curreryRetrun(data[ric].currency)
                        this.CurrentWarrantBidV = `${this.$$.curreryRetrun(data[ric].currency)} ${data[ric].BID}`
                        this.negitive = this.$$.changeRate(data[ric].BID_PCTCHNG) >= 0 ? true : false
                        this.CurrentWarrantBidVChange = `${this.$$.changeRate(this.$$.formatNumber(data[ric].BID_PCTCHNG, 1, 0))} %`
                        this.date = new Date().toDateString()
                        this.convRatio = Number(data[ric].conv_ratio)
                        this.CalBid = this.calculatePrice()
                        this.newCalBid = `0.0 %`
                        this.bid = data[ric].BID
                        this.formControl(data[ric].maturity);
                        $('#WarrantCalculator').find('.percent').find('i').css({
                            left: '60px'
                        })
                    }
                },
                error: function (jqXHR) {

                }
            });
        },
        Reset: function () {
            this.UnderlingTargetPrice = this.ResetUnderlingTargetPrice
            this.UnderlingTargetPricePercent = 0.0
            $(".form-control").datepicker("setDate", new Date(new Date().toDateString()))
            $('#WarrantCalculator').find('.percent').find('i').css({
                left: '60px'
            })
            this.Currency = this.CurrencyAgain
            this.CalBid = this.calculatePrice()
            this.newCalBid = `0.0 %`
        },
        // Underling Target Price input方法
        UnderlingTargetInput: function (e) {
            this.UnderlingTargetPrice = e.target.value
            this.UnderlingTargetPricePercent = this.$$.formatNumber((this.$$.formatNumber(e.target.value, 2, 1) - this.UnderlingTargetPricecount) / this.UnderlingTargetPricecount * 100, 1, 0)
            this.CalBid = this.calculatePrice()
            if (this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) > 0) {
                this.newCalBid = '+ ' + this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) + " %"
            } else {
                this.newCalBid = this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) + " %"
            }

            this.newnegitive = parseFloat(this.CalBid) - parseFloat(this.bid) >= 0 ? true : false
            $('#WarrantCalculator').find('.percent').find('i').css({
                left: (this.UnderlingTargetPricePercent.toString().length * 10) + 30 + 'px'
            })
        },
        UnderlingTargetPercentInput: function (e) {
            let num = 0
            this.UnderlingTargetPricePercent = e.target.value
            this.UnderlingTargetPrice = this.$$.formatNumber(Number(this.UnderlingTargetPricecount) * (1 + Number(this.UnderlingTargetPricePercent) / 100), 2, 0)
            this.CalBid = this.calculatePrice()
            if (this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) > 0) {
                this.newCalBid = '+ ' + this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) + " %"
            } else {
                this.newCalBid = this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) + " %"
            }

            this.newnegitive = parseFloat(this.CalBid) - parseFloat(this.bid) >= 0 ? true : false
            if (e.target.value.length <= 1) {
                num = 60
            } else {
                num = e.target.value.length * 10 + 20
            }
            $('#WarrantCalculator').find('.percent').find('i').css({
                left: num + 'px'
            })
        },
        // 日历选择器的方法
        formControl: function (maturity) {
            let _this = this
            $(".form-control")
                .datepicker({
                    autoclose: true,
                    dateFormat: "dd M yy",
                    minDate: new Date(new Date().toDateString()),
                    maxDate: new Date(maturity),
                    onSelect: function (dateText, inst) {
                        _this.date = dateText
                        _this.CalBid = _this.calculatePrice()
                        if (_this.$$.formatNumber((parseFloat(_this.CalBid) - parseFloat(_this.bid)) / parseFloat(_this.bid) * 100, 1, 0) > 0) {
                            _this.newCalBid = '+ ' + _this.$$.formatNumber((parseFloat(_this.CalBid) - parseFloat(_this.bid)) / parseFloat(_this.bid) * 100, 1, 0) + " %"
                        } else {
                            _this.newCalBid = _this.$$.formatNumber((parseFloat(_this.CalBid) - parseFloat(_this.bid)) / parseFloat(_this.bid) * 100, 1, 0) + " %"
                        }

                        _this.newnegitive = parseFloat(_this.CalBid) - parseFloat(_this.bid) > 0 ? true : false
                    }
                })
                .datepicker("setDate", new Date(new Date().toDateString()));
        },
        // 时间计算
        intervalDate: function (old, news) {
            return ((old - news) / (1 * 24 * 60 * 60 * 1000)) / 365
        },
        // 计算值
        calculatePrice: function () {
            let underlyingPriceCal, exercisePriceCal, num;
            underlyingPriceCal = parseFloat(this.UnderlingTargetPrice);
            exercisePriceCal = parseFloat(this.exercisePrice);
            $.each(this.exchangeratio, (i, c) => {
                if (this.CalculatorData.underlying_curr == c.currency) {
                    underlyingPriceCal = parseFloat(this.UnderlingTargetPrice) / parseFloat(c.rate)
                    exercisePriceCal = parseFloat(this.exercisePrice) / parseFloat(c.rate)
                }
            });
            num = this.$$.blackScholes(
                underlyingPriceCal,
                exercisePriceCal,
                this.intervalDate(new Date(this.CalculatorData.LSTTRDDATE).setHours(0,0,0,0), new Date(this.date).setHours(0,0,0,0)),
                parseFloat(this.CalculatorData.v_implied_volatility) / 100,
                0.03,
                this.CalculatorData.type
            )
            return this.$$.formatNumber(num / this.convRatio, 3, 0)
        },
        // to StructureYourWarrant页面
        toStructureYourWarrant: function () {
            this.$router.push({ name: "Structure Your Warrant", params: { data: this.CalculatorData } })
            localStorage.setItem("StructureYourName", JSON.stringify(this.CalculatorData))
        },
        // 出现combobox
        ariseCombobox: function (id) {
            let _this = this
            this.$$.jqcombobox(id)
            $(id).combobox({
                select: function (event, ui) {
                    this.ricNmae = this.value
                    _this.GetMarketData(this.value)
                }
            });
            $(id).parent().find('input').attr('placeholder', this.$t('WCr.SW'))
        },
    }
};